import { useMediaQuery } from "@chakra-ui/react";
import React, { Attributes } from "react";
import styled from "styled-components";

import { BlockRenderer } from "src/components/molecules/BlockRenderer";
import { BackgroundColorType } from "src/services/contentful/twoStackColumn/twoStackColumnParser";
import { IFlexDisplayFields } from "src/types/generated/contentful";
import { down, up } from "styled-breakpoints";

type Props = {
  children: React.ReactNode;
  headerColor?: BackgroundColorType;
  fw?: string;
  fv?: string; // justify content
  fh?: string; // align items
};

export interface FlexWrapperProps extends Attributes {
  direction?: string;
  fixedWidth?: string;
  // layoutName: "Default" | "Center Items in a Row"; Possible values for layoutName
}

const FlexWrapper = styled.div<any>`
  display: flex;
  ${(props: any) =>
    props.direction && props.direction.toLowerCase() === "row"
      ? "flex-direction:row; gap: 2em;"
      : "flex-direction:column;"}

  flex-wrap: wrap;
  position: relative;

  ${(props: any) =>
    props.fv
      ? "justify-content:" +
        props.fv
          .toLowerCase()
          .replace("middle", "center")
          .replace("top", "flex-start")
          .replace("bottom", "flex-end")
      : ""};
  ${(props: any) =>
    props.fh
      ? "align-items:" +
        props.fh
          .toLowerCase()
          .replace("middle", "center")
          .replace("left", "flex-start")
          .replace("right", "flex-end")
      : ""};

  width: 100%;
  height: 100%;

  ${down("lg")} {
    ${(props: any) =>
      props?.fixedWidth && props?.direction.toLowerCase() === "column"
        ? `width: 100%`
        : ""};
    ${(props: any) =>
      props?.fixedWidth && props?.direction.toLowerCase() === "column"
        ? `max-width: 100%`
        : ""};
  }
  ${up("md")} {
    ${(props: any) =>
      props?.fixedWidth && props?.direction.toLowerCase() === "column"
        ? `width: ${props.fixedWidth}%`
        : ""};
    ${(props: any) =>
      props?.fixedWidth && props?.direction.toLowerCase() === "column"
        ? `max-width: ${props.fixedWidth}%`
        : ""};
  }
`;
/*
const Wrapper = styled.ul<FlexDisplayProps>`
  display: inline-block;
  margin: 0 auto;
  padding: 0;

  > * {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 18px;
    margin-top: calc(5px / 2);
    padding-bottom: calc(5px / 2);

    &::before {
      content: "";

      ${({ theme, headerColor }) =>
        headerColor &&
        `background: ${
          // eslint-disable-next-line
          theme.palette.common[backgroundColorPalette[headerColor]]
        };`}

      width: 5px;
      height: 5px;
      min-width: 5px;
      min-height: 5px;
      border-radius: 50%;
      display: inline-block;
      line-height: 2rem;
      text-align: left;
      margin-right: 0.5rem;
      margin-top: 0.5rem;
      vertical-align: middle;
    }

    p {
      margin: 0;
    }

    span {
      text-align: left;
    }
  }
`;
*/
const Header = styled.h3``;

const Wrapper = styled.div<any>`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export type FlexDisplayProps = {
  headerColor?: BackgroundColorType;
  id?: string;
  fw?: string;
  fh?: string;
  fv?: string;
  "data-layoutid"?: string;
  children?: JSX.Element | JSX.Element[];
} & Attributes &
  IFlexDisplayFields;

const FlexDisplay = (data: FlexDisplayProps) => {
  // console.log("FlexDisplay", data);
  let inboundFromContentful = false;

  const d = Object.assign({}, data);
  const { direction, content, title, id, children } = d;

  if (d.flexDisplayHorizontalAlignment) {
    d.fh = d.flexDisplayHorizontalAlignment;
    inboundFromContentful = true;
  }

  if (data.flexDisplayVerticalAlignment) {
    d.fv = d.flexDisplayVerticalAlignment;
    inboundFromContentful = true;
  }

  const [isMobile] = useMediaQuery("(min-width: 768px)");

  const fixedWidth = d.fw;
  const gap = content?.length === 2 ? 3 : 1;

  const fw =
    direction === "Row" && content && content.length > 0
      ? 100 / content.length - gap
      : undefined;
  //console.log("FlexDisplay", title, fixedWidth, fw, content);
  //<Wrapper data-cid={id} width={`${fixedWidth}%`}>

  return (
    <FlexWrapper
      data-cid={id}
      data-layoutid={data["data-layoutid"]}
      fixedWidth={fixedWidth}
      {...d}
    >
      {d?.heading ? (
        <BlockRenderer
          layoutId={`${d["data-layoutid"]}-heading`}
          block={d?.heading}
        ></BlockRenderer>
      ) : null}
      {children
        ? children
        : getItems(content, fw, d["data-layoutid"], isMobile)}
    </FlexWrapper>
  );
};

const getItems = (content: any, fw: any, layoutId?: string, isMobile?: any) => {
  //console.log(JSON.stringify(content));
  let orderedContent = JSON.parse(JSON.stringify(content));
  // use this to force images to the top of the content if on mobile, sometimes the images are to the right instead of the left in rows
  // we may need some kind of checkbox to force images to reorder in a mobile view.

  //TODO: need to add this back so that items can be ordered
  // if (!isMobile && layoutId && layoutId?.indexOf("footer") <= 0) {
  //   orderedContent = sortBy(orderedContent, "sys.contentType.sys.id");
  // }
  return orderedContent && orderedContent.length > 0
    ? orderedContent.map((item: any, i: number) => {
        // console.log("item", item);

        return (
          <BlockRenderer
            layoutId={`${layoutId}-${i}`}
            key={i}
            block={item}
            fw={fw}
          />
        );
      })
    : null;
};

export default React.memo(FlexDisplay);

// const FlexDisplay = ({ children, ...rest }: Props) => (
//   <FlexWrapper>
//     <Wrapper {...rest}>{children}</Wrapper>
//   </FlexWrapper>
// );
